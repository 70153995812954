import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import './index.css';

import * as dataApi from '../../api/dataApi';

function getContentHeight() {
  let contentHeight = 800;
  if (typeof window !== 'undefined') {
    contentHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
  }
  return contentHeight - 100;
}

function getHeadingList() {
  if (typeof window !== 'undefined') {
    return [...document.getElementsByTagName('h2')].map((h2) => h2);
  }

  return [];
}

function TOC({ siteConfig, children }) {
  const [headingList, setHeadingList] = useState(null);
  const [scrollbarHeight, setScrollbarHeight] = useState(getContentHeight());
  const [visibleHeadingIndex, setVisibleHeadingIndex] = useState(0);
  const ignoreScrollTimeout = useRef();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHeadingList(getHeadingList());
    }
  }, []);

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => setScrollbarHeight(getContentHeight()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  function scrollHandler() {
    if (!ignoreScrollTimeout.current) {
      const _headingList = getHeadingList();
      if (_headingList) {
        let _visibleHeadingIndex = -1;

        _headingList.map((heading, headingIndex) => {
          const rect = heading.getBoundingClientRect();
          if (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
              (window.innerWidth || document.documentElement.clientWidth)
          ) {
            _visibleHeadingIndex = headingIndex;
          }
        });

        if (!_visibleHeadingIndex !== -1) {
          setVisibleHeadingIndex(_visibleHeadingIndex);

          const toc = document.getElementById('toc_' + _visibleHeadingIndex);
          if (toc) {
            dataApi.scrollToElementInContainer(
              toc.parentElement.parentElement,
              toc,
              0
            );
          }
        }
      }
    }
  }

  return headingList && headingList.length ? (
    <div className="toc-sidebar">
      <Scrollbars style={{ height: scrollbarHeight }}>
        <ul>
          {headingList.map((heading, headingIndex) => (
            <li
              className={headingIndex === visibleHeadingIndex ? 'in-view' : ''}
              id={'toc_' + headingIndex}>
              <Button
                onClick={() => {
                  const yOffset = -80;
                  const y =
                    heading.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;

                  window.scrollTo({ top: y, behavior: 'smooth' });

                  ignoreScrollTimeout.current = setTimeout(() => {
                    ignoreScrollTimeout.current = null;
                  }, 2000);

                  setVisibleHeadingIndex(headingIndex);
                }}>
                {heading.innerText}
              </Button>
            </li>
          ))}
        </ul>
        {children ? children : null}
      </Scrollbars>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TOC);
